import React, { useState } from 'react';
import DefaultLayout from "../../component/pageComponent/DefaultLayout";
import { Table, Tag, Space } from 'antd'

interface Iprops {
    columns: any,
    data: any,
}

const TableComponent: React.FC<Iprops> = (props) => {



    return (
        <Table
            columns={props.columns}
            dataSource={props.data}
            pagination={{ defaultPageSize: 100, pageSizeOptions: ['50', '100', '1000', '10000', '100000'] }}
            scroll={{ x: 'fit-content' }}
            size="small"
            loading={props.data === undefined} />);
}

export default TableComponent;