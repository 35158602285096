import React,{useEffect,useState} from 'react';
import DefaultLayout from "../../component/pageComponent/DefaultLayout";
import {IMainRouter} from "../../routing/MainRouter"
import Table from '../../component/Table';
import {getRequest} from "../../core/Function/request"

import Document from "../../core/interface/document"
import * as GlobalVariables from "../../core/GlobalVariables"
import {useParams} from "react-router-dom"
interface RouteParams{
    id?:string,
    name?:string
}


export interface IAddProduct extends IMainRouter{
}


interface colums{
    title:string,
    dataIndex:string,
    key:string,
    render?:Function
}

const Documents:React.FC<IAddProduct> = (props) => {

    const [data, setData] = useState<Document[] | null>();
    const [searchString, setSearchString] = useState<string>("");
    const params = useParams<RouteParams>();
    
    const colums = [
    {
        title: "Document ID",
        dataIndex:"id",
        key:"id"
    },    {
        title: "Kenteken",
        dataIndex:"license",
        key:"license"
    },    {
        title: "Beschrijving",
        dataIndex:"discription",
        key:"discription"
    }
] as colums[]

    const responseHandler = (response:any) => {
           if (response) {
            if (response.hasOwnProperty("error")){
                console.log(response.status, response.error, props.token) 
                setData(null)
            }else if (response.data?.hasOwnProperty("error")){
                console.log(response.status, response.data.error) 
                setData(null)
            }else if (response.data[0].hasOwnProperty("license")){
               setData(response.data) 
            }
            
    }}

    useEffect(() => {

            getRequest(
                responseHandler, 
                typeof props.token === "string" ? props.token : "", 
                "/document/all");
    },[])

    return ( 
    <DefaultLayout {...props} title={`Kenteken ${params.id ? "> " + params.name : ""}`} search={setSearchString} newLink={`/${GlobalVariables.projectName}/add/license`}>
        <>
        <Table columns={data !== null ? colums : null} data={data !== null ? searchString === "" ? data : data?.filter(product => product.discription.toLowerCase().includes(searchString.toLowerCase()) || product.license.toLowerCase().includes(searchString.toLowerCase())) : null}/> 
        </>
    </DefaultLayout>);
}

export default Documents;