import React,{useState} from "react"
import {IMainRouter} from "../../../routing/MainRouter"
import "./style.scss"
import DefaultLayout from "../../../component/pageComponent/DefaultLayout"
import {Input, Form, Button, Row, Col, message} from 'antd'
import {postRequest} from "../../../core/Function/request"
export interface IAddCattegorie extends IMainRouter{

}

const icons = ["fa fa-ambulance",
"fa fa-cab",
"fa fa-plane",
"fa fa-subway",
"fa fa-car",
"fa fa-rocket",
"fa fa-bicycle",
"fa fa-ship",
"fa fa-train",
"fa fa-bus ",
"fa fa-motorcycle",
"fa fa-truck",
"fa fa-th",
"fa fa-battery-empty",
"fa fa-television",
"fa fa-object-group",
"fa fa-bed",
"fa fa-briefcase",
"fa fa-suitcase",
"fa fa-tablet",
"fa fa-tags",
			 
"fa fa-square",
"fa fa-file",
"fa fa-circle",
"fa fa-circle-o",
"fa fa-dot-circle-o",
			 
"fa fa-plus-square-o",
"fa fa-square-o",
"fa fa-minus-square-o",
"fa fa-credit-card",
			 
"fa fa-area-chart",
"fa fa-line-chart",
"fa fa-bank",
"fa fa-bell-o",
"fa fa-home",];
const AddCattegorie:React.FC<IAddCattegorie> = (props) => {
    const [icon , setIcon] = useState<string>("");
    
    const saveHandler = (formItems:any) => {
        postRequest(handleResponse,  
            typeof props.token === "string" ? props.token : "",
             "/categorie/new",
              {
            name: formItems.name, 
            icon:icon
        })
    }

    const handleResponse= (response: any) => {
        if (response.data.hasOwnProperty("success")){
                message.success("categorie is toegevoegd!");
                return;
        }
        message.error("er is iets fout gegaan")   
    }


    return (
    <DefaultLayout {...props}>
                <Form name="basic"initialValues={{remember: true,}}  layout="vertical"  onFinish={saveHandler}>
            <Row gutter={[16,16]}>
                <Col md={12} sm={24}>

    
                    <Form.Item label="Categorie Naam" name="name" rules={[ {
                        required: true,
                        message: 'Please provide a categorie name!',
                        }, ]}>
                        <Input placeholder="Categorie naam" prefix={<i className={icon} />} />
                    </Form.Item>
                                            <Button type="primary" htmlType="submit" >
                            Save categorie
                        </Button>
                </Col>

                <Col md={12} sm={24}>
                    <div style={{paddingTop:30}}>
                        {icons.map(i => <div className="add-cat-button-holder"><Button shape="round" onClick={() => setIcon(i)} icon={<i className={i} />} /></div>)}
                    </div>
                    
                </Col>
                </Row>
            </Form>
    </DefaultLayout>);
}

export default AddCattegorie