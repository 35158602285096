import Axios from "axios";
import {backendURL} from "../GlobalVariables"
const loginFunction = async (callBack:Function, email:string, pass:string) => {
    Axios.post(backendURL + "/authenticate/user", {
      email: email,
      password: pass,
    }).then(
      (response:any) => {
        callBack(response, email, pass);
      },
      (error:any) => {
        if (error.response) {
          callBack(error.response.data);
        } else {
          callBack(null);
        }
      }
    );
}

export default loginFunction;