import React, {useEffect, useState} from 'react';
import {Form, Input,Button, Card, message, Modal} from 'antd';
import {FullScreenCenteredLayout} from "../../component"
import {IAuthRouter} from "../../routing/AuthRouter"
import {sha256} from "js-sha256";
import {userFromStorage} from "../../core/Function/loginLocalStorage"

import "./style.scss"
const  Login:React.FC<IAuthRouter>= (props) => {

    const [showModel, setShowModel] = useState<boolean>(false);
    const onFinish = (values:userFromStorage) => {
      props.login(values.email, sha256(values.password))
    };

    useEffect(() => {
        if (props.error){
          message.error(props.error)   
        }
    }
    ,[props.error])

    return (
    <FullScreenCenteredLayout>
        <>        
          
          <Card style={{ minWidth:'250px', width:'25vw', maxWidth:'500px' }} className="card-with-shadow" title={<h1 className="-company-title"><span>Fub</span>igm<span>o</span></h1>}>
            
            <Form name="basic"initialValues={{remember: true,}} onFinish={onFinish} layout="vertical">
              <Form.Item label="E-mail" name="email" rules={[ {
                  required: true,
                  message: 'Please input your email!',
                }, ]} >
                <Input />
              </Form.Item>
              <Form.Item label="Password" name="password" rules={[ {
                    required: true,
                    message: 'Please input your password!',
                  }, ]} >
                <Input.Password />
              </Form.Item>

                        <Modal
                            title="Forgot password?"
                            visible={showModel}
                            onOk={() => setShowModel(!showModel)}
                            onCancel={() => setShowModel(!showModel)}
                        >
                            <p>Functie bestaat nog niet, Bart kan je wachtwoord vast aanpassen 😄</p>
                        </Modal>
              <Form.Item >
                <Button type="primary" htmlType="submit" style={{backgroundColor:'orange', borderColor:'orange'}}>
                  Submit
                </Button>

                <span className="login-form-forgot-a" onClick={() => setShowModel(!showModel)}>
                    Forgot password?
                </span>
              </Form.Item>
            </Form>
          </Card>
        </>

    </FullScreenCenteredLayout>);
}

export default Login; 