import React, {useState} from "react"
import {IMainRouter} from "../../../routing/MainRouter"
import "./style.scss"
import {DefaultLayout} from "../../../component"
import * as icons from "@ant-design/icons";
import {Input, Form, Button, Row, Col, Select, Upload, DatePicker, message} from 'antd'

import {postRequest} from "../../../core/Function/request"
import Categorie from "../../../core/interface/categorie";

const {TextArea} = Input;
const { Option } = Select;


export interface IAddProduct extends IMainRouter{

}

const AddLicense:React.FC<IAddProduct> = (props) => {

    const [image, setImage] = useState<any | null>();
    const [categories, setCategories] = useState<Categorie[]>()


    const onFinischHandler = (s: any) =>{
      
        
        postRequest(handlePostResponse,  
            typeof props.token === "string" ? props.token : "",
            "/document/new", 
            s);

    }

    


        const handlePostResponse = (response: any) => {
        if (response.data.hasOwnProperty("success")){
            if (response.data.success === "posted new document"){
                message.success("Kenteken is Toegevoegd")
                return;
            }
        }
        message.error("er is iets fout gegaan")   
    }

    return (
    <DefaultLayout {...props} categorieCallback={setCategories}>
        <Form name="basic"initialValues={{remember: true,}}  layout="vertical"  onFinish={onFinischHandler}>
            <Row gutter={[16,16]}>
                <Col md={12} sm={24}>

                    <Form.Item label="Kenteken" name="license" rules={[ {
                        required: true,
                        message: 'Please provide a license',
                        }, ]} >
                        <Input placeholder="license.."/>
                    </Form.Item>

                    <Form.Item label="Beschrijving" name="discription" rules={[ {
                        required: true,
                        message: 'Please provide a discription!',
                        }, ]} >
                        <TextArea showCount placeholder="Write something about the product"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" >
                            Voeg Kenteken toe
                        </Button>
                    </Form.Item>
                </Col>

                <Col md={12} sm={24}>

                </Col>
                </Row>
            </Form>
    </DefaultLayout>);
}


export default AddLicense