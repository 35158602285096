import React from "react"
import {IMainRouter} from "../../routing/MainRouter"
import "./style.scss"
import {FullScreenCenteredLayout} from "../../component"
import './style.scss'
export interface IAddLicense extends IMainRouter{

}

const PageNotFound:React.FC<IAddLicense> = (props) => {

    return (
        <FullScreenCenteredLayout>
            <>
                <img src="https://www.nationalacademic.nl/sites/all/modules/aserv/aserv_media/images/banaan-gezond3.jpg" className="page-not-found-image"/>
                <h1 className="page-not-found-h1">404 whoops... looks like nothing is here</h1>
            </>
        </FullScreenCenteredLayout>
    );
}

export default PageNotFound