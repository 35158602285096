import React, { useState, useEffect } from 'react';
import './App.scss';
import MainRouter from "./routing/MainRouter"
import AuthRouter from "./routing/AuthRouter";
import getLoginFromLocalStorage, { userFromStorage, setLoginLocalStorage } from "./core/Function/loginLocalStorage";
import loginFunction from "./core/Function/login"
import addZero from "./core/Function/addZero"
import UserInterface from "./core/interface/user"
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios from "axios";
import { backendURL } from "./core/GlobalVariables"

const AppWrapper: React.FC = () => {
    const [authError, setAuthError] = useState<String | null>(null);
    const [selectedCompany, setSelectedCompany] = useState<number>(1);
    const [login, setLogin] = useState(false);
    const [token, setToken] = useState<string | boolean | null>(false);
    const [user, setUser] = useState<UserInterface>();

    useEffect(() => {
        if (!login) {
            reAuth();
        }
    }, []);

    // Function that will be called to refresh authorization
    const refreshAuthLogic = (failedRequest: any) => axios.post(backendURL + "/authenticate/user", {
        email: getLoginFromLocalStorage()?.email,
        password: getLoginFromLocalStorage()?.password,
    }).then(tokenRefreshResponse => {
        localStorage.setItem('token', tokenRefreshResponse.data.token);
        failedRequest.response.config.headers['Authorization'] = tokenRefreshResponse.data.token;
        return Promise.resolve();
    });

    // Instantiate the interceptor (you can chain it as it returns the axios instance)
    createAuthRefreshInterceptor(axios, refreshAuthLogic);

    const reAuth = () => {
        const savedUserData: userFromStorage | undefined = getLoginFromLocalStorage();
        if (savedUserData !== undefined) {
            loginFunction(HandleResponse, savedUserData.email, savedUserData.password);
        }
    }

    const HandleResponse = (response: any, user: string | null = null, pass: string | null = null) => {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        if (response) {
            if (response.hasOwnProperty("error")) {
                const d = new Date();
                setAuthError(`[${d.getHours()}:${addZero(d.getMinutes())}:${addZero(d.getSeconds())}] ${response.error}`);
                return;
            }
            if (response.data) {
                if (response.data.hasOwnProperty("error")) {
                    const d = new Date();
                    setAuthError(`[${d.getHours()}:${addZero(d.getMinutes())}:${addZero(d.getSeconds())}] ${response.data.error}`);
                    return;
                }
                if (response.data.hasOwnProperty("token") && response.data.hasOwnProperty("token_expire") && response.data.hasOwnProperty("token_expire")) {
                    setLoginLocalStorage(user, pass);
                    setToken(response.data.token);
                    setUser(response.data as UserInterface);
                    //setTokenExpire(moment(response.data.token_expire));
                    setLogin(true);
                    return;
                }
            }
        }
        const d = new Date();
        setAuthError(`[${d.getHours()}:${addZero(d.getMinutes())}:${addZero(d.getSeconds())}] Dumpsterfire`);

    }


    const logout = () => {
        setToken(null);
        //setTokenExpire(false);
        setLogin(false);
        localStorage.removeItem('email');
        localStorage.removeItem('password');
    }

    if (getLoginFromLocalStorage() && !login) {
        return <>Loggin in...</>;
    }
    else if (login) {
        return (<MainRouter logout={logout} reAuth={reAuth} token={token} selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} user={user} />);
    }
    else {
        //return (<MainRouter logout={logout} reAuth={reAuth} token={token} selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany}/>);
        return (<AuthRouter login={(u: string, p: string) => loginFunction(HandleResponse, u, p)} error={authError} />);
    }
}


function App() {
    return (
        <AppWrapper />
    );
}

export default App;
