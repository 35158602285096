import React , {ReactChild, useState}from 'react';
import "./style.scss"
import {Drawer } from "antd";




type Iprops = {
    children: ReactChild,
    showDrawer: boolean,
    setShowDrawer:Function,
    title:string
}

const  DrawerTemplate: React.FC<Iprops> = (props) => {


    return ( 
                 <Drawer
        title={props.title}
        placement="right"
        closable={false}
        onClose={() => props.setShowDrawer(false)}
        visible={props.showDrawer}
        width={600}
      >

        {props.children}
      </Drawer>
);
}

export default DrawerTemplate;