import React from 'react';
import "./style.scss"
import  {  List, Avatar, Space  } from "antd";
import CardInterface from "../../core/interface/CardInterface"
import * as icons from "@ant-design/icons";

const {FullscreenOutlined, DownloadOutlined} = icons;

const ListCard: React.FC<CardInterface> = (props) => {
    return (
        <List.Item
                key={props.title}
                actions={[
                    <Space>
                        <FullscreenOutlined />
                        {`Length:${props.length} Width:${props.width} Height:${props.height}`}
                    </Space>,
                    <Space>
                        <DownloadOutlined />
                        {`${props.weight} Tonn`}
                    </Space>

                    
                ]}
            >
                <List.Item.Meta
                avatar={
                <img
                    width={100}
                    alt="logo"
                    src="https://i.pinimg.com/originals/db/98/e9/db98e931881ff3279e6108074c38283e.png"
                />
                }
                title={props.title}
                description={`${props.location} ${props.time}`}
                />
                {props.discription}
            </List.Item>
    );
};

export default ListCard;