
import React from 'react';
import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect,
} from "react-router-dom";

import {projectName} from "../core/GlobalVariables"

import Login from "../page/Login"

export interface IAuthRouter{
    login:Function,
    error:String | null
}

const AuthRouter:React.FC<IAuthRouter> = (props) => {
    return (
    <Router>
        <Switch>
            <Route exact path={`/${projectName}/login`} render={() => <Login {...props}/>} />

            <Route component={() => <Redirect to={`/${projectName}/login`} />} />
        </Switch>
    </Router>
    );     
}

export default AuthRouter;