import React, { ReactChild, useState, useEffect } from 'react';
import "./style.scss"
import { Layout, Menu, Avatar, Popover, Button, Breadcrumb, Input } from "antd";
import * as icons from "@ant-design/icons";
import * as GlobalVariables from "../../../core/GlobalVariables"

import { withRouter, NavLink } from "react-router-dom"
import { RouteComponentProps } from "react-router";

import "./style.scss";

import { IMainRouter } from "../../../routing/MainRouter"
import userInterface from '../../../core/interface/user';
import DrawerTemplate from "../Drawer"
import userEvent from '@testing-library/user-event';
import Company from "../../../core/interface/company"
import { getRequest } from "../../../core/Function/request"
import Categeogrie from "../../../core/interface/categorie"
const {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShoppingCartOutlined,
  IdcardOutlined,
  UnorderedListOutlined,
  SearchOutlined
} = icons;

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;



interface Iprops extends IMainRouter, RouteComponentProps<any> {
  children: ReactChild,
  title?: string,
  search?: Function,
  categorieCallback?: Function,
  newLink?: string
}

const DefaultLayout: React.FC<Iprops> = (props) => {

  const [collapsedState, setCollapsedState] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [data, setData] = useState<Company[] | null>(null);
  const [searchTimeout, setSearchTimeout] = useState<any>(null);
  const [categorie, setCategorie] = useState<Categeogrie[]>();

  const responseHandler = (response: any) => {
    if (response) {
      if (response.hasOwnProperty("error")) {
        console.log(response.status, response.error, props.token)
      }
      if (response.data?.hasOwnProperty("error")) {
        console.log(response.status, response.data.error)
      }
      setData(response.data)
    }
  }

  useEffect(() => {
    getRequest(
      responseHandler,
      typeof props.token === "string" ? props.token : "",
      "/company/all");

    getRequest(HandleCategorieResponse,
      typeof props.token === "string" ? props.token : "",
      "/categorie/all/company_id/" + props.selectedCompany);
  }
    , [])


  const HandleCategorieResponse = (response: any) => {
    if (response) {
      if (response.hasOwnProperty("error")) {

        return;
      }
      if (response.data.hasOwnProperty("error")) {
        return;
      }
      if (response.data[0].hasOwnProperty("icon")) {
        setCategorie(response.data)

        if (props.categorieCallback) {
          props.categorieCallback(response.data)
        }
        return;
      }
    }
  }


  const searchHandler = (s: string) => {
    if (props.search !== undefined) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      setSearchTimeout({
        typingTimeout: setTimeout(function () {
          if (props.search !== undefined) {
            props.search(s);
          }
        }, 300)
      });

    }

  }

  const contentDrawer = (
    <>
      <div>
        <Button onClick={() => props.logout()}>Log uit</Button>
      </div>
      {
        props.user?.rank === 1 ?
          <div>
            <Button style={{ width: '100%' }}>Administrator</Button>
          </div>
          : null
      }

    </>
  );

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsedState}>
        <div className="logo-wrapper">
          <div className="logo" >
            <h2>{collapsedState ? GlobalVariables.PN : GlobalVariables.projectName}</h2>
          </div>
        </div>

        <Menu theme="dark" mode="inline" defaultSelectedKeys={['/']} selectedKeys={[props.location.pathname]}>
          <Menu.Item key={`/${GlobalVariables.projectName}`} icon={<ShoppingCartOutlined />}>
            <NavLink to={`/${GlobalVariables.projectName}`}>
              Alle Producten
            </NavLink>
          </Menu.Item>
          <Menu.Item key={`/${GlobalVariables.projectName}/documents`} icon={<IdcardOutlined />}>
            <NavLink to={`/${GlobalVariables.projectName}/documents`}>
              Nieuwe kentekens
            </NavLink>
          </Menu.Item>
          <SubMenu key="3" icon={<UnorderedListOutlined />} title="Producten Categorie">
            {categorie !== undefined ?
              categorie.map(c => <Menu.Item key={`/${GlobalVariables.projectName}/product/categorie/${c.id}`} icon={<i className={`fa ${c.icon}`} />}>
                <NavLink to={`/${GlobalVariables.projectName}/product/categorie/${c.id}/${c.name}`}>{c.name}</NavLink>
              </Menu.Item>)
              : null}
            <Menu.Item key={`/${GlobalVariables.projectName}/add/categorie`} icon={<i className={`fa fa-plus-square-o`} />}>
              <NavLink to={`/${GlobalVariables.projectName}/add/categorie`}>Nieuwe Categorie</NavLink>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-header" style={{ padding: 0 }}>

          <Menu theme="dark" mode="horizontal" className="menu-color-red" defaultSelectedKeys={['2']}>
            {React.createElement(collapsedState ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: () => setCollapsedState(!collapsedState),
            })}

            {data !== undefined ? data?.map((s) => <Menu.Item onClick={() => props.setSelectedCompany(s.id)} key={s.name.replace(" ", "_")}>{s.name}</Menu.Item>) : null}
            <Menu.Item key="derp" style={{ float: 'right' }} onClick={() => setShowDrawer(true)}>
              <Avatar

                style={{
                  backgroundColor: 'blue',
                  verticalAlign: 'middle',
                }}
                size="large"
                gap={1}
              >
                {props.user?.name.substr(0, 2)}
              </Avatar>
            </Menu.Item>
          </Menu>


        </Header>
        <DrawerTemplate showDrawer={showDrawer} setShowDrawer={setShowDrawer} title={props.user?.name || "Woops"}>{contentDrawer}</DrawerTemplate>
        {props.title ? <div className="site-layout-page-title">
          <div style={{ float: 'left' }}>
            {props.title}
            {props.newLink ? <NavLink to={props.newLink}><Button >Nieuw</Button></NavLink> : null}
          </div>
          {props.search !== undefined ?
            <div style={{ float: 'right' }}>
              <Input onChange={(s) => searchHandler(s.target.value)} prefix={<SearchOutlined />} placeholder="Search..." />
            </div>
            : null}

        </div> : null}
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280
          }}
        >
          {props.children}
        </Content>
        <Footer className="site-layout-background"> <span>© 2020</span> <span style={{ float: 'right' }}>Software by {GlobalVariables.companyName}</span></Footer>
      </Layout>
    </Layout>);
}

export default withRouter(DefaultLayout);