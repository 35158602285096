import React from 'react';
import "./style.scss"
import {Card as AntCard} from "antd";
import CardInterface from "../../core/interface/CardInterface"


const { Meta } = AntCard;

const  Card: React.FC<CardInterface> = (props) => {
    return (
    <AntCard
        hoverable
        style={{ width: '100%'}}
        cover={<img alt="example" src="https://i.pinimg.com/originals/db/98/e9/db98e931881ff3279e6108074c38283e.png" />}
    >
        <Meta title={props.title} className="card-meta-data" description={
        <>
            <p className="time">{props.time}</p>
            <p className="location">{props.location}</p>
        </>} />
    </AntCard>);
};

export default Card;
