import React, {useState} from 'react';
import {Image, Empty, Input, Form, Row, Col, Select, Button, DatePicker, Tag, message } from "antd"
import "./style.scss"
import IProduct from "../../core/interface/product";
import Categories from "../../core/interface/categorie"
import * as icons from "@ant-design/icons";
import {putRequest, getRequest, postRequest} from "../../core/Function/request"
import Product from '../../core/interface/product';
const {TextArea} = Input;
const { Option } = Select;


const {CalendarOutlined, EuroOutlined} = icons

function clean(obj:any) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
}

interface Iprops {
    product: IProduct | undefined,
    categories: Categories[] | undefined,
    token:boolean | String | null,
    updateProduct:Function
}

const  ProductVieuw: React.FC<Iprops> = (props) => {

    const [image, setImage] = useState<any>();
    const [edite, setEdite] = useState<boolean>(false);
    const [newProduct, setNewProduct] = useState<IProduct | undefined>(props.product);
    
    const updateProductHandler = (formInput:any) => {

        if(image !== undefined){
            var formData = new FormData();
            formData.append("file", image[0]);
            postRequest((r:any) => updateProduct(formInput, r),  
                typeof props.token === "string" ? props.token : "",
                "/products/image", 
                formData);
        }else{
            putRequest((s: any) => handleProductUpdate(s, typeof newProduct !== "undefined" ? { ...newProduct, ...formInput} : null),
            typeof props.token === "string" ? props.token : "",
            "/products/update/product_id/" + props.product?.id, 
            clean(formInput));
        }
        //let data = formInput.filter((s:any) => s !== undefined);

    }

    const updateProduct = (formInput:any, response:any) => {
        if (response.data.hasOwnProperty("success")){
            putRequest((s: any) => handleProductUpdate(s, typeof newProduct !== "undefined" ? { ...newProduct, ...formInput} : null),
            typeof props.token === "string" ? props.token : "",
            "/products/update/product_id/" + props.product?.id, 
            {...clean(formInput), picture_url:response.data.success});
            }else
            message.error("er is iets fout gegaan")   
    }
    const changeSoldStatus = (status:number) => {
        var date = "";
        const dateHolder = new Date();
        if(status === 1)
            date = dateHolder.getDay() + "-" + (dateHolder.getMonth() + 1) + "-" + dateHolder.getFullYear()

        getRequest((s: any) => handleProductUpdate(s, typeof newProduct !== "undefined" ? { ...newProduct, status: status, sold_date:date} : null),
            typeof props.token === "string" ? props.token : "",
            "/products/sold/product_id/" + props.product?.id + "/status/" + status);


    }

    const handleProductUpdate = (response: any, newProduct:Product | null) => {
        if (response.data.hasOwnProperty("message")){
            if (response.data.message === "success"){
                message.success("Product is geupdate")
                if(newProduct !== null)
                    setNewProduct(newProduct)
                    props.updateProduct(newProduct)
                return;
            }
        }
        message.error("er is iets fout gegaan")   
    }

if (props.product !== undefined)
    return ( 
        <div key={props.product.id}>
            <Image src={image ? URL.createObjectURL(image[0]) : "http://www.pmostert.com/" + props.product.picture_url} />

          <Form name="basic"initialValues={{remember: true,}}  layout="vertical"  onFinish={updateProductHandler}>
            <Row gutter={[16,16]}>
                <Col md={12} sm={24}>
                    <Form.Item label="Categorie" name="product_categorie"   >
                        <Select
                            bordered={edite}
                            disabled={!edite}
                            showSearch
                            placeholder="Select a Categorie"
                            optionFilterProp="children"
                            defaultValue={props.product.product_categorie}
                            >
                                {props.categories !== undefined ? props.categories.map(c => <Option value={c.id.toString()}>{c.name}</Option>) : null}
                        </Select>
                </Form.Item>
                 <Form.Item label="Inkoop prijs" name="buy_price">
                        <Input placeholder="prijs" prefix={<EuroOutlined />} defaultValue={props.product.buy_price} bordered={edite} disabled={!edite}/>
                    </Form.Item>
                    <Form.Item label="Verkoop prijs" name="asking_price" >
                        <Input placeholder="prijs" prefix={<EuroOutlined />} defaultValue={props.product.asking_price} bordered={edite} disabled={!edite}/>
                    </Form.Item>
                    <Form.Item label="Kortings prijs" name="discount_price" >
                        <Input placeholder="prijs" prefix={<EuroOutlined />} defaultValue={props.product.discount_price} bordered={edite} disabled={!edite}/>
                    </Form.Item>
                </Col>

                <Col md={12} sm={24}>

   
                    <Form.Item label="Datum korting" name="discount" >
                        <Input defaultValue={props.product.discount} prefix={<CalendarOutlined />} bordered={edite} disabled={!edite}/>
                    </Form.Item>
                    <Form.Item label="Aankoop datum" name="date" >
                       <Input defaultValue={props.product.date} prefix={<CalendarOutlined />} bordered={edite} disabled={!edite}/>
                    </Form.Item>
                    <Form.Item label="Verkoop status" name="status" >
                            <Tag color={newProduct?.sold_date === "" ? "red" : "green"} key={newProduct?.sold_date}>{newProduct?.sold_date  === "" ? "Niet verkocht" : "verkocht: " + props.product.sold_date }</Tag>
                        
                    </Form.Item>
                    <Form.Item label="Bedrijf" name="bedrijf" >
                        {props.product.company_name}
                    </Form.Item>
                </Col>
                </Row>
                <Form.Item label="Beschrijving" name="description"  >
                        <TextArea showCount placeholder="Write something about the product" defaultValue={props.product.description} bordered={edite} disabled={!edite}/>
                    </Form.Item>
            {edite ? 
            <>
            <Form.Item><input type='file' id='file' name='file' className="upload" onChange={(e) => setImage(e.target.files !== null ? e.target.files : "")} accept="image/gif, image/jpeg, image/png" /></Form.Item>
                               
            <Form.Item>
                        <Button type="primary" htmlType="submit" >
                            Save product
                        </Button>
                         <Button  onClick={() => setEdite(false)} style={{marginLeft:15}} >
                            cancel
                        </Button>
                            <Button style={{ marginLeft: 15 }} onClick={() => changeSoldStatus(newProduct?.sold_date === "" ? 1 : 0)}>{newProduct?.sold_date  === "" ? "Verander naar verkocht" : "verander naar NIET verkocht"}</Button>
                    </Form.Item></> : null}
            </Form>
            {edite ? null : <Form.Item>
                        <Button type="primary" htmlType="submit" onClick={() => setEdite(true)} >
                            Verander product
                            
                        </Button>
                       
                    </Form.Item>}

        </div>
    );

    return (<Empty />)
}

export default ProductVieuw;