import React, {useState} from "react"
import {IMainRouter} from "../../../routing/MainRouter"
import "./style.scss"
import {DefaultLayout} from "../../../component"
import * as icons from "@ant-design/icons";
import {Input, Form, Button, Row, Col, Select, Upload, DatePicker, message} from 'antd'
import SkeletonImage from "antd/lib/skeleton/Image";
import {postRequest} from "../../../core/Function/request"
import Categorie from "../../../core/interface/categorie";

const {TextArea} = Input;
const { Option } = Select;

const { Dragger } = Upload;

const {InboxOutlined, EuroOutlined} = icons

export interface IAddProduct extends IMainRouter{

}

const AddProduct:React.FC<IAddProduct> = (props) => {

    const [image, setImage] = useState<any>();
    const [categories, setCategories] = useState<Categorie[]>()
    const onFinischHandler = (s: any) =>{
        console.log(image)
        if(image !== undefined){
            var formData = new FormData();
            formData.append("file", image[0]);
            postRequest((r:any) => handleUpload(r, s),  
                typeof props.token === "string" ? props.token : "",
                "/products/image", 
                formData);
        }else{
            handleUpload({data:{success:""}}, s)
        }


    }

    const handleUpload = (response:any, s:any) => {
        if (response.data.hasOwnProperty("success")){
        postRequest(handleProductUpdate,  
            typeof props.token === "string" ? props.token : "",
            "/products/new/company_id/" + props.selectedCompany, 
            {...s, picture_url:response.data.success});
        }else
            message.error("er is iets fout gegaan")   
    }

        const handleProductUpdate = (response: any) => {
        if (response.data.hasOwnProperty("message")){
            if (response.data.message === "success"){
                message.success("Product is toegevoegd")
                return;
            }
        }else
            message.error("er is iets fout gegaan")   
    }

    return (
    <DefaultLayout {...props} categorieCallback={setCategories}>
        <Form name="basic"initialValues={{remember: true,}}  layout="vertical"  onFinish={onFinischHandler}>
            <Row gutter={[16,16]}>
                <Col md={12} sm={24}>
                    <Form.Item label="Categorie" name="product_categorie" rules={[ {
                        required: true,
                        message: 'Please select a Categorie!',
                        }, ]} >
                        <Select
                            showSearch
                            placeholder="Select a Categorie"
                            optionFilterProp="children"
                            >
                            {categories !== undefined ? categories.map(c => <Option value={c.id.toString()}>{c.name}</Option>) : null}
                      
                        </Select>

                    </Form.Item>
                    <Form.Item label="Beschrijving" name="description" rules={[ {
                        required: true,
                        message: 'Please select a Categorie!',
                        }, ]} >
                        <TextArea showCount placeholder="Write something about the product"/>
                    </Form.Item>
                                        <Form.Item label="Upload a file" name="file" >
                        
                      <input type='file' id='file' name='file' className="upload" onChange={(e) => setImage(e.target.files !== null ? e.target.files : "")} accept="image/gif, image/jpeg, image/png" />
                    <img  key={image} src={ image ? URL.createObjectURL(image[0]) : ""} width="100%" alt=""/>
                    </Form.Item>

                    
                </Col>

                <Col md={12} sm={24}>

                    <Form.Item label="Inkoop prijs" name="buy_price" rules={[ {
                        required: true,
                        message: 'Please provide a pice!',
                        }, ]}>
                        <Input placeholder="default size" prefix={<EuroOutlined />} />
                    </Form.Item>
                    <Form.Item label="Verkoop prijs" name="asking_price" rules={[ {
                        required: true,
                        message: 'Please provide a pice!',
                        }, ]}>
                        <Input placeholder="default size" prefix={<EuroOutlined />} />
                    </Form.Item>
                    <Form.Item label="Kortings prijs" name="discount_price" rules={[ {
                        required: true,
                        message: 'Please provide a pice!',
                        }, ]}>
                        <Input placeholder="default size" prefix={<EuroOutlined />} />
                    </Form.Item>
                    <Form.Item label="Datum korting" name="discount">
                        <DatePicker style={{width:'100%'}} format={"DD/MM/YYYY"} />
                    </Form.Item>

                    
                    <Form.Item>
                        <Button type="primary" htmlType="submit" >
                            Voeg product toe
                        </Button>
                    </Form.Item>
                </Col>
                </Row>
            </Form>
    </DefaultLayout>);
}


export default AddProduct