import Axios from "axios";
import {backendURL} from "../GlobalVariables";

const getRequest = (callBack:Function, auth:string, url:string) => {

    Axios.get(backendURL  + url,{headers: {"Authorization":auth}})
        .then(
            response => { callBack(response) },
            error => {
                if (error.response) {
                    callBack(error.response);
                } else {
                    callBack(null);
                }
            });
}

const postRequest = (callBack:Function, auth:string, url:string, body:object) => {
    Axios.post(backendURL + url, body, {
      headers: { "Authorization": auth },
    }).then(
          response => { callBack(response) },
            error => {
                if (error.response) {
                    callBack(error.response);
                } else {
                    callBack(null);
                }
            }
    );
};

const putRequest = (callBack:Function, auth:string, url:string, body:object) => {
    Axios.put(backendURL + url, body, {
      headers: { "Authorization": auth },
    }).then(
          response => { callBack(response) },
            error => {
                if (error.response) {
                    callBack(error.response);
                } else {
                    callBack(null);
                }
            }
    );
};

const requestResponseHandler = (response:any) => {
    if (response) {
            if (response.hasOwnProperty("error")){
                return response.status;
            }
            if (response.data.hasOwnProperty("error")){
                return response.status;
            }
            return response.data
}}

export { getRequest, postRequest, requestResponseHandler, putRequest};