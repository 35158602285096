import React, { useEffect, useState } from 'react';
import DefaultLayout from "../../component/pageComponent/DefaultLayout";
import { Tag, Space } from 'antd'
import { IMainRouter } from "../../routing/MainRouter"
import Table from '../../component/Table';
import { getRequest, requestResponseHandler } from "../../core/Function/request"
import DrawerTemplate from "../../component/pageComponent/Drawer"
import * as icons from "@ant-design/icons";
import Product from "../../core/interface/product"
import ProductView from "../../component/productVieuw"
import Categorie from "../../core/interface/categorie"
import { useParams } from "react-router-dom"
import * as GlobalVariables from "../../core/GlobalVariables"
interface RouteParams {
    id?: string,
    name?: string
}


export interface IAddProduct extends IMainRouter {
}


interface colums {
    title: string,
    dataIndex: string,
    key: string,
    render?: Function
}

const { InfoCircleTwoTone } = icons;

const Home: React.FC<IAddProduct> = (props) => {

    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [data, setData] = useState<Product[] | null>();
    const [drawerData, setDrawerData] = useState<Product>();
    const [searchString, setSearchString] = useState<string>("");
    const [categories, setCategories] = useState<Categorie[]>()
    const params = useParams<RouteParams>();

    const colums = [
        {
            title: "Product ID",
            dataIndex: "id",
            key: "id"
        }, {
            title: "Bedrijf",
            dataIndex: "company_name",
            key: "company_name"
        }, {
            title: "Categorie",
            dataIndex: "categorie_name",
            key: "categorie_name"
        }, {
            title: "Aankoop datum",
            dataIndex: "date",
            key: "date"
        }, {
            title: "Status",
            dataIndex: "sold_date",
            key: "sold_date",
            render: (s: string) => <Tag color={s === "" ? "red" : "green"} key={s}>{s === "" ? "Niet verkocht" : "verkocht: " + s}</Tag>
        }, {
            title: "Inkoop prijs",
            dataIndex: "buy_price",
            key: "buy_price"
        }, {
            title: "Verkoop prijs",
            dataIndex: "asking_price",
            key: "asking_price"
        }, {
            title: "Kortings prijs",
            dataIndex: "discount_price",
            key: "discount_price"
        }, {
            title: "Beschrijving",
            dataIndex: "description",
            key: "description"
        }, {
            title: "Info",
            dataIndex: "show_more",
            key: "show_more",
            render: (image: string, object: Product) => <InfoCircleTwoTone onClick={() => { setDrawerData(object); setShowDrawer(true) }} />
        }
    ] as colums[]

    const responseHandler = (response: any) => {
        if (response) {
            if (response.hasOwnProperty("error")) {
                console.log(response.status, response.error, props.token)
                setData(null)
            } else if (response.data?.hasOwnProperty("error")) {
                console.log(response.status, response.data.error)
                setData(null)
            } else {
                setData(response.data)
            }

        }
    }

    const updateProduct = (product: Product) => {
        setData(
            data?.map(d => d.id === product.id ? product : d)
        );
    }

    const mapKeyToDate = (data: any[] | undefined) => {
        if (typeof data === 'undefined')
            return undefined

        return data.map(d => {
            return { ...d, key: d.id + "-item-key" }
        })
    }
    useEffect(() => {

        if (params.id !== undefined) {
            getRequest(
                responseHandler,
                typeof props.token === "string" ? props.token : "",
                "/products/all/categorie_id/" + params.id + "/company_id/" + props.selectedCompany);
        } else {
            getRequest(
                responseHandler,
                typeof props.token === "string" ? props.token : "",
                "/products/all/company_id/" + props.selectedCompany);
        }
    }, [])

    return (
        <DefaultLayout {...props} title={`Producten ${params.id ? "> " + params.name : ""}`} search={setSearchString} categorieCallback={setCategories} newLink={`/${GlobalVariables.projectName}/add/product`}>
            <>
                <DrawerTemplate showDrawer={showDrawer} setShowDrawer={setShowDrawer} title={`${drawerData?.categorie_name} | ${drawerData?.id}`}>
                    <ProductView product={drawerData} categories={categories} token={props.token} updateProduct={updateProduct} />
                </DrawerTemplate>
                <Table key="tableeee" columns={data !== null ? colums : null} data={data !== null ? searchString === "" ? mapKeyToDate(data) : mapKeyToDate(data?.filter(product => product.description.toLowerCase().includes(searchString.toLowerCase()))) : null} />
            </>
        </DefaultLayout>);
}

export default Home;