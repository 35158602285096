import React , {ReactChild}from 'react';
import "./style.scss"
import {Row, Col} from "antd";

type Iprops = {
    children: ReactChild
}

const  FullScreenCenteredLayout: React.FC<Iprops> = (props) => {
    return (
     <Row justify="center" align="middle" className="full-screen-centered-page-row">
            <Col>
                <div className="fscpr-container">
                {props.children}
                </div>
            </Col>
    </Row>);
}

export default FullScreenCenteredLayout;