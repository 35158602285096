export interface userFromStorage{
    email:string,
    password:string
}

const getLoginFromLocalStorage = ():userFromStorage| undefined => {
    const email:string|null = localStorage.getItem("email") || null;
    const password:string|null = localStorage.getItem("password") || null;

    if (email !== null && password !== null){
        return {
            email,
            password
        } as userFromStorage
    }

    return undefined;
}

export const setLoginLocalStorage = (username:string|null,password:string|null) => {
    if(username && password){
        localStorage.setItem('email', username);
        localStorage.setItem('password', password);
    }
}

export default getLoginFromLocalStorage;