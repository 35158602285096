import React from 'react';
import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect,
} from "react-router-dom";
import { projectName } from "../core/GlobalVariables"

import { Home, AddProduct, AddCattegorie, AddLicense, PageNotFound, Documents } from "../page"
import UserInterface from "../core/interface/user"

export interface IMainRouter {
    logout: Function,
    reAuth: Function,
    token: String | boolean | null,
    selectedCompany: number,
    setSelectedCompany: Function,
    user: UserInterface | undefined
}

const MainRouter: React.FC<IMainRouter> = (props) => {
    return (
        <Router>
            <Switch>

                <Route path={`/${projectName}/add/product`} component={() => <AddProduct {...props} />} />
                <Route path={`/${projectName}/add/license`} component={() => <AddLicense {...props} />} />
                <Route path={`/${projectName}/add/categorie`} component={() => <AddCattegorie {...props} />} />

                <Route exact path={`/${projectName}/product/categorie/:id/:name`} component={() => <Home {...props} />} />

                <Route exact path="/" component={() => <Redirect to={`/${projectName}`} />} />
                <Route exact path={`/${projectName}/login`} component={() => <Redirect to={`/${projectName}`} />} />
                <Route exact path={`/${projectName}`} component={() => <Home {...props} />} />
                <Route exact path={`/${projectName}/documents`} component={() => <Documents {...props} />} />
                <Route component={() => <PageNotFound {...props} />} />
            </Switch>
        </Router>
    );
}

export default MainRouter;